import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './VideoStyles.css'

import spaceVideo from '../assets/space.mp4'
import StarsCanvas from './canvas/Stars'
import { FaAndroid, FaApple } from 'react-icons/fa'
import { AiFillAndroid } from 'react-icons/ai'

const Video = () => {
    const text = "Hey Boss, Welcome to UNIVERSE BY HUMANS!"
    const speed = 100
    const delay = 2000
    const [displayedText, setDisplayedText] = useState('');
    const [isTyping, setIsTyping] = useState(true);

    useEffect(() => {
      let index = 0;
      let typingInterval;
  
      const startTyping = () => {
        typingInterval = setInterval(() => {
          if (index < text.length) {
            setDisplayedText((prev) => prev + text[index]);
            index++;
          } else {
            clearInterval(typingInterval);
            setIsTyping(false);
          }
        }, speed);
      };
  
      if (isTyping) {
        startTyping();
      } else {
        const resetTimeout = setTimeout(() => {
          setDisplayedText('');
          setIsTyping(true);
        }, delay);
        return () => clearTimeout(resetTimeout);
      }
  
      return () => clearInterval(typingInterval);
    }, [isTyping, text, speed, delay]);

    return (
        <div className='hero'>
            {/* <video autoPlay loop muted id='video'>
                <source src={spaceVideo} type='video/mp4' />
            </video> */}

                    <div className='flex'>
                      <StarsCanvas />
                      </div>
                                        {/* <h1 style={{ marginLeft: '5rem', 
                                        color: '#00d8ff',
                                        letterSpacing: 10,
                                        paddingTop: 20,
                                        }} >UNIVERSE</h1> */}

                      <div className="hero-text">
                      <div className="text-center self-center flex items-center px-10 py-5 justify-center top-32 absolute">
      <h1 className="text-lg font-bold text-white">{displayedText}</h1>
    </div>
    </div>
                      <div className="hero-text">
                      {/* <div style={{flexDirection: 'row', }}> */}

                       <a href='https://apps.apple.com/ng/app/universe-by-humans/id6444775214'>
                       <FaApple size={200} style={{ padding: 10, borderRadius: 10}}/>
                       </a> 
                        
                       <a href='https://play.google.com/store/apps/details?id=com.alluniverses.universe'>
                        <AiFillAndroid size={200} style={{ padding: 10, borderRadius: 10}}/>
                       </a> 
                          {/* <h1>Decentralized</h1> */}
                          {/* <h1><span className="blue">Social Media </span> Protocol</h1>
                          <p>Guaranteed share liquidity UNITS for billions of users any where in the universe. Wait! Are you even in the UNIVERSE ?</p>
                          <div className="btn-group">
                              <button className="btn"> App Store</button>
                              <button className="btn btn-outline">Google Play</button>
                          </div> */}
                      {/* </div> */}
                      <div className=" z-50 items-center text-center text-white absolute bottom-32">
              <div className='text-sm'>
                SOCIAL MEDIA CAN BE BENEFICIAL FOR ALL
              </div>
              <div className="text-xs">
               Explore, Earn and Connect.
              </div>
            <div className="flex items-center justify-center">
          <Link
            // onClick={openModal}
            // to={'/shop'}
            to={'/Preview'}
            className="block animate-pulse px-7 py-4 text-sm text-center text-white"
          >

            Preview
            <div className="h-[2px] w-[100px] bg-white" />
          </Link>
          <Link
            // to={'/Customization'}
            // onClick={openModal}
            to={'/staff'}
            className="block animate-pulse px-7 py-4 text-sm text-center text-white "
          >

           Jobs
          <div className="h-[2px] w-[60px] bg-white" />
          </Link>
        </div>

        </div>
                      </div>
            {/* <div className='content'>
                <h1>Decentralized</h1>
                <h1><span className="blue">Social Media </span> Protocol</h1>
                <p>World's first civilian space travel.</p>
                <div>
                    <Link to='/training' className='btn'>App Store</Link>
                    <Link to='/contact' className='btn btn-light'>Google Play</Link>
                </div>
            </div> */}
        </div>
    )
}

export default Video
