import React from 'react'
import {Routes, Route} from 'react-router-dom'
import Contact from './routes/Contact';

import Home from './routes/Home'
import Pricing from './routes/Pricing'
import Training from './routes/Training';
import About from './routes/About';
import Event from './routes/Event';
import TNC from './routes/TNC';
import Payments from './routes/Payments';
import Staff from './routes/Staff';
import Preview from './routes/Preview';
import Success from './routes/Success';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='About' index element={<About />}/>
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/training' element={<Training />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/Event' element={<Event />} />
        <Route path='/tnc' element={<TNC />} />
        <Route path='/staff' element={<Staff />} />
        <Route path='/Preview' element={<Preview />} />
        <Route path='/pay' element={<Payments />} />
        <Route path='/suc' element={<Success />} />
      </Routes>
    </>
  );
}

export default App;
