import React from 'react'
import '../App.css';
import Navbar from '../components/Navbar';
// import Hero from './components/hero/Hero'
import A from '../components/about/A';
// import Developer from './components/developer/Developer';
// import Subscribe from './components/subscribe/Subscribe';
import Footer from '../components/footer/Footer';
import DevAbout from '../components/developer/DevAbout';

function About() {
  return (
    <div style={{backgroundColor: '#000'}}>
      <Navbar />
      {/* <Hero /> */}
      <DevAbout />
      <A />
      {/* <Subscribe /> */}
      <Footer />
    </div>
  );
}

export default About;
