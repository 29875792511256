import React, {useState} from 'react'
import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai'
import './Navbar.css'

const Navbar = () => {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const [color, setColor] = useState(false)
    const changeColor =() => {
        if(window.scrollY >= 100) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    return (
        // <div className='navbar'  >
            <div className={color ? 'navbar navbar-bg' : 'navbar'} style={{justifyContent: 'space-between'}}>

            <div className="container">
                <a href='/'>

                <h1 style={{ marginLeft: '1rem', color: '#00d8ff'}} >UNIVERSE</h1>
                </a>
                <ul className={click ? 'nav active' : 'nav'}>
                    {/* <li className="nav-item">
                        <a href="/training">Developers</a>
                    </li> */}
                    <li className="nav-item">
                        <a href="/Event">Events</a>
                    </li>
                    <li className="nav-item">
                        <a href="/pricing">Platform</a>
                    </li>
                    <li className="nav-item">
                        <a href="/training">Community</a>
                    </li>
                    <li className="nav-item">
                        <a href="/About">About</a>
                    </li>
                    <li className="nav-item">
                        <a className='btn' href="/contact">Contact Us</a>
                    </li>
                </ul>
                <div onClick={handleClick} className="hamburger">
                    {click ? (<AiOutlineClose className='icon' />) : (<AiOutlineMenu className='icon' />)}
                    
                </div>
            </div>
        </div>
    )
}

export default Navbar
