import React from 'react'
import './FooterStyles.css'
import { FaAppStore, FaFacebook, FaGooglePlay, FaInstagram, FaLinkedin, FaMailBulk, FaPhone, FaSearchLocation, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='left'>
                    <div className='location'>
                        <FaSearchLocation size={20} style={{ color: '#ffffff', marginRight: '2rem' }} />
                        <div>
                            <p>Location</p>
                            <h4>Africa</h4>
                        </div>
                    </div>
                    <div className='phone'>
                        <h4><FaPhone size={20} style={{ color: '#ffffff', marginRight: '2rem' }} /> +234-814-122-5357</h4>
                    </div>
                    <div className='email'>
                        <h4><FaMailBulk size={20} style={{ color: '#ffffff', marginRight: '2rem' }} /> support@alluniverses.live</h4>
                    </div>
                </div>
                <div className='right'>
                    <h4>UNIVERSE BY HUMANS TECHNOLOGY SERVICES</h4>
                    <p>"Our continuously evolving features 
                        empower you to express yourself 
                        in new ways, Connect You with more people, build influence,
                         and create compelling content that's distinctly yours"</p>
                    <div className='social'>
                        <a href='/'>
                            <FaFacebook size={20} style={{ color: '#ffffff', marginRight: '1rem' }} />
                            </a>
                        <a href='/'>
                            <FaTwitter size={20} style={{ color: '#ffffff', marginRight: '1rem' }} />
                            </a>
                        <a href='/'>
                            <FaLinkedin size={20} style={{ color: '#ffffff', marginRight: '1rem' }} />
                            </a>
                        <a href='/'>
                            <FaInstagram size={20} style={{ color: '#ffffff', marginRight: '1rem' }} />
                            </a>
                        <a href='/'>
                            <FaYoutube size={20} style={{ color: '#ffffff', marginRight: '1rem' }} />
                            </a>
                        <a href='/'>
                            <FaTiktok size={20} style={{ color: '#ffffff', marginRight: '1rem' }} />
                            </a>
                        <a href='/'>
                            <FaGooglePlay size={20} style={{ color: '#ffffff', marginRight: '1rem' }} />
                            </a>
                        <a href='/'>
                            <FaAppStore size={20} style={{ color: '#ffffff', marginRight: '1rem' }} />
                            </a>
                            
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer
