import React, { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { MdOutlineShoppingCart } from "react-icons/md";
import PaystackForm from "../components/PaystackForm";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
// import Nav from "../component/Nav";
// import Homes from "./Homes";
// import MobileNav from "./MobileNav";
import { Link, useNavigate } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { FaArrowCircleDown, FaWallet } from "react-icons/fa";
// import Google from "./Google";
import Son from "../assets/seb.jpg";
import { useFlutterwave, closePaymentModal, } from 'flutterwave-react-v3';




// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAvfSZiORsVohwIB696oyJYwxBcuh-jRRY",
  authDomain: "markers-digital-pro.firebaseapp.com",
  projectId: "markers-digital-pro",
  storageBucket: "markers-digital-pro.appspot.com",
  messagingSenderId: "99311584893",
  appId: "1:99311584893:web:63167d001bb5c569f31314",
  measurementId: "G-H43QCYF1XV"
};



firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

const Payments = () => {
  const {
    isEmpty,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();

  const [productQuantities, setProductQuantities] = useState({});

  const handlePaymentSuccess = (response, userData) => {
    console.log("Payment successful:", response);

    const payeeName = userData.name;
    const payeeLocation = userData.location;
    const payeeEmail = userData.email;
    const payeePostalCode = userData.postalCode;
    const payeePhoneNumber = userData.phoneNumber;

    const productsInCart = items.map((item) => ({
      id: item.id,
      name: item.title,
      quantity: productQuantities[item.id] || item.quantity,
    }));

    const data = {
      amount: cartTotal,
      createdAt: new Date(),
      productPaidFor: items.map((item) => item.title).join(", "),
      product_image: items.map((item) => item.product_image).join(", "),
      quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      payeeLocation,
      payeeEmail,
      payeePostalCode,
      payeePhoneNumber,
      productsInCart,
      status: 'Successful'

    };

    firestore
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Payments")
      .add(data)
      .then((docRef) => {
        console.log("Payment data saved with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error saving payment data:", error);
      });

      // navigate()

      
    };
    
    // const navigate = <Link to={'/order'} />

  const handlePaymentClose = () => {
    console.log("Payment cancelled");
  };


  const handleOrderAttempts = (response, userData) => {
    const payeeName = userData.name;
    const payeeLocation = userData.location;
    const payeeEmail = userData.email;
    const payeePostalCode = userData.postalCode;
    const payeePhoneNumber = userData.phoneNumber;

    const productsInCart = items.map((item) => ({
      id: item.id,
      name: item.title,
      quantity: productQuantities[item.id] || item.quantity,
    }));

    const data = {
      amount: cartTotal,
      createdAt: new Date(),
      productPaidFor: items.map((item) => item.title).join(", "),
      product_image: items.map((item) => item.product_image).join(", "),
      quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      payeeLocation,
      payeeEmail,
      payeePostalCode,
      payeePhoneNumber,
      productsInCart,
      status: 'Attempt'
    };
    firestore
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("OrderAttempt")
    .add(data)
    .then((docRef) => {
      console.log("Payment data saved with ID:", docRef.id);
    })
    .catch((error) => {
      console.error("Error saving payment data:", error);
    }); 
   };

   const [user, setUser] = useState(null);


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);


//   const sm1 = require("../Assets/00.jpg")
const config = {
    // public_key: 'FLWPUBK_TEST-b1459ab49c7f0e8ca89a52b43d7d2028-X',
    public_key: 'FLWPUBK-25270fd838a65ff9111e1db7994439a6-X',
    // public_key: 'FLWPUBK-f3745c8499f9e76b41eb308c4994e59a-X',
    tx_ref: Date.now(),
    amount: 50000,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    // customer: {
    //   email: email || 'user@gmail.com' ,
    //    phone_number: PhoneNumber || '08141225457',
    //   name: Name || 'User',
    // },
    customer: {
        email: 'support@alluniverses.livem',
         phone_number: '070********',
        name: 'New User',
      },
    customizations: {
      title: 'UNIVERSE',
      description: 'Payment',
    //   logo: 'https://firebasestorage.googleapis.com/v0/b/universe-ff7ed.appspot.com/o/Private%2Ficon200.png?alt=media&token=9a6c4171-52c8-47a3-a900-cb8f85705484',
      logo: 'https://firebasestorage.googleapis.com/v0/b/universe-ff7ed.appspot.com/o/Private%2Flogo100.PNG?alt=media&token=ca3b99e8-5e21-4b60-8077-f846ca6f8df3',
    //   logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  const email1 = ''

  const handleFlutterPayment = useFlutterwave(config);

  const handleChangeEmail = (e) => {
    // 👇 Get input value from "event"
    setEmail(e.target.value);
  };
  const handleChangeName = (e) => {
    // 👇 Get input value from "event"
    setName(e.target.value);
  };
  const handleChangePhoneNumber = (e) => {
    // 👇 Get input value from "event"
    setPhoneNumber(e.target.value);
  };
  const [email, setEmail] = useState('')
  const [Name, setName] = useState('')
  const [PhoneNumber, setPhoneNumber] = useState('')

  return (
    <div className=" w-[100%] p-10 mt-10 bg-[#000]"
    style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}

    >
      <h1 className="text-blue-50 font-extrabold text-6xl text-center m-0">
        
        50,000 Naira Payment To UNIVERSE
      </h1>
      <h1 className="text-blue-50 font-extrabold text-2xl text-center mt-10">
        
       For Training In the following area: <br/>
       {/* UI/UX Design <br/> */}
       {/* Brand Design <br/> */}
       Web Design &<br/>
       Mobile App Development<br/>
       {/* Digital Marketing/Social Media Marketing <br/> */}
       {/* Manipulating Social Media By Selling Likes/Followers/Subscribers/Comments/Views/Plays ETC <br/> */}
       {/* Project Management <br/> */}
       {/* Content Management <br/> */}
       {/* Content Creation <br/> */}
       {/* Copywriting <br/> */}

      </h1>

      {/* <div
        className=" lg:flex gap-5 justify-center text-center text-white items-center p-0"
      >
        <div className="mt-20 md:flex gap-5">
        <div>
          <label> Full Name</label>
          <br/>
          <input
          className="bg-[#333] p-2"
            type="text"
            name="name"
            value={Name}
            onChange={handleChangeName}
          />
        </div>
        <div>
          <label> Email Address</label>
          <br/>
          <input
                    className="bg-[#333] p-2"
            type="email"
            name="email"
            value={email}
            onChange={handleChangeEmail}
          />
        </div>
        <div>
          <label> Phone Number</label>
          <br/>
          <input
                    className="bg-[#333] p-2"
                    type="text"
                    name="phoneNumber"
                    value={PhoneNumber}
                    onChange={handleChangePhoneNumber}
                    />
        </div>
      
      
      </div> 
      </div>  */}
      <div className="justify-content-center">




          <div
            // style={{ }}
            className="bg-light-black text-light p-10 md:flex grid items-center justify-center"
          >
            <div className="p-2 md:flex" md={4}>
              <div
                variant="danger"
                className="m-2 text-1xl cursor-pointer hover:text-green-500 hover:bg-white text-center items-center flex font-bold p-5 drop-shadow-2xl shadow-2xl bg-green-500 text-white rounded-lg"
                // onClick={() => emptyCart()}
                onClick={() => {
                    handleFlutterPayment({
                      callback: (response) => {
                         console.log(response);
                          closePaymentModal() // this will close the modal programmatically
                      },
                      onClose: () => {},
                    });
                  }}
              >
                 <img alt="oku" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzAQIHnLrLEAgb4UuoKNqx3BpqHu9fd7_tkof7foHahw&s" className="mr-2 h-10 w-10" />
                 {/* <FaWallet size="1.7rem" className="mr-2" /> */}
       Pay With Flutterwave
                     </div>

              {/* <button
                variant="success"
              >
                <PaystackForm

                  amount={5000} // Use the cart total as the payment amount
                //   email={firebase?.auth()?.currentUser?.email} // Replace with the customer's email
                  onSuccess={handlePaymentSuccess}
                  onClose={handlePaymentClose}
                  handleOrderAttempts={handleOrderAttempts}
                />
              </button> */}
            </div>
            </div>

      </div>
         {/* <div className="bg-gray-900">
          <Homes />
        </div> */}
    </div>
  );
};

export default Payments;
