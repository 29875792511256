import React from 'react'

function Success() {
  return (
    <div
    className='h-screen self-center items-center'
    style={{alignItems: 'center', alignSelf: 'center', justifyContent: 'center', backgroundColor: '#fff', textAlign: 'center'}}>
        

<div>


        {/* <h4 className='text-black'>Successful</h4> */}
        <img src="https://cdn.dribbble.com/users/1751799/screenshots/5512482/media/1cbd3594bb5e8d90924a105d4aae924c.gif" style={{alignSelf: 'center', }} alt="success" />
        {/* <img src="https://www.lappymaker.com/images/greentick-unscreen.gif" style={{alignSelf: 'center', }} alt="success" /> */}

</div>


    </div>
  )
}

export default Success