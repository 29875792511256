import React, { Component } from 'react'
import './HeroImageStylesEVENT.css'

class HeroImage extends Component {
    render() {
        return (
            <div className='heroEVENT-img'>
                <div className='headingEVENT'>
                    <h1>{this.props.heading}</h1>
                    <p style={{textAlign: 'center'}}>{this.props.text}</p>
                </div>
            </div>
        )
    }
}

export default HeroImage
