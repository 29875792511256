import React from 'react'
import Footer from '../components/footer/Footer'
import HeroImage from '../components/HeroImage'
import Navbar from '../components/Navbar'
import TrainingSection from '../components/Training'

const Training = () => {
    return (
        <div style={{backgroundColor: '#000'}}>
            <Navbar />
            <HeroImage heading='TRAINING.' text='Pre-Flight & In-Flight Training.' />
            <div style={{backgroundColor: '#000'}}>

            <TrainingSection />
            </div>
            <Footer />
        </div>
    )
}

export default Training
