import React from 'react'
import './A.css'
import { SiHiveBlockchain, SiStrapi, SiFsecure } from 'react-icons/si';
import { VscServerProcess } from 'react-icons/vsc'
import ACard from './ACard';


const A = () => {
    return (
        <div className='a'>
            {/* <div className='videos'

            // style={{zIndex: -10}}

>

<div
dangerouslySetInnerHTML={{
__html: `<video  style="
position: relative;
width: 100%;
top: 0;
height: 100%;
object-fit: cover;
// z-index: -1;" 
autoplay loop muted playsinline>
<source src=${video} type="video/mp4" />
Your browser does not support the video tag.
</video>`
}}
/>
</div> */}
        <div className='a'>
 
            <div className="containe"
            >
                <h2>TEAM</h2>
                <p>
                Stephjas met and joined forces with now co-founder and creative director - Oluchi Binta Nwachukwu Esq. To re-envision and restructure into a radically independent brand. 
                                   </p>
                <div className="car-containe">
                    <div >
                    <div 
                    className="car"
                    >                    
                    </div>

                                    <h3 style={{color: '#fff', paddingTop: 30}}>Oku Akpabio</h3>
                    <p style={{color: '#fff', paddingBottom: 30}}>
                    CEO & Founder                                       </p>
                    </div>

                    <div >
                    <div 
                    className="car"
                    >
                    </div>

                                    <h3 style={{color: '#fff', paddingTop: 30}}>Oku Akpabio</h3>
                    <p style={{color: '#fff', paddingBottom: 30}}>
                    CEO & Founder                                       </p>
                    </div>
                    <div >
                    <div 
                    className="car"
                    >                    
                    </div>

                                    <h3 style={{color: '#fff', paddingTop: 30}}>Oku Akpabio</h3>
                    <p style={{color: '#fff', paddingBottom: 30}}>
                    CEO & Founder                                       </p>
                    </div>

                    <div >
                    <div 
                    className="car"
                    >
                    </div>

                                    <h3 style={{color: '#fff', paddingTop: 30}}>Oku Akpabio</h3>
                    <p style={{color: '#fff', paddingBottom: 30}}>
                    CEO & Founder                                       </p>
                    </div>


                </div>
                {/* <div className="car-containe">
                    <div 
                    className="car"
                    >                    </div>
                    <div 
                    className="car"
                    >
                    </div>
                    <div 
                    className="car"
                    >
                    </div>
                    <div 
                    className="car"
                    >
                    </div>
                </div> */}
                <a href="/" className="btn">Join now</a>
            </div>

  
        </div>
        </div>
    )
}

export default A
