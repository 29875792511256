import React from 'react'
import Footer from '../components/footer/Footer'
import HeroImage from '../components/HeroImage'
import Navbar from '../components/Navbar'
import Form from '../components/Form'

const Contact = () => {
    return (
        <div >
            <Navbar />
            <HeroImage heading='CONTACT' text='UNIVERSE' />
            <div style={{backgroundColor: '#000'}}>

            <Form />
            </div>
            <Footer/>
        </div>
    )
}

export default Contact
