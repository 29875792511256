import React, { useState } from 'react'
// import Banner from './h.png'
// import logo from './logo.png'
import Banner from '../assets/uu.png'
// import { usePaystackPayment } from "react-paystack";
import firebase from "../firebaseconfig";
import validator from 'validator';
import { useEffect } from "react";
import { BiArrowBack, BiCheckDouble, BiSolidBusiness, BiSolidGroup } from 'react-icons/bi'
import { AiFillCloseCircle } from 'react-icons/ai'
// import { useSeerbitPayment } from 'seerbit-reactjs'

const PaystackForm = ({ email, onSuccess, onClose, handleOrderAttempts }) => {
    const [Cat, setCat] = useState([]);
    const [data, setData] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [fellowships, setFellowship] = useState([]);
    const [events, setEvents] = useState([]);
    const [states, setStates] = useState([]);
    const [groups, setGroups] = useState([]);
    const [priests, setPriests] = useState([]);
    const [tithes, setTithes] = useState([]);
    const [Area, setArea] = useState([]);
    const [Zone, setZone] = useState([]);
    const [Bethel, setBethel] = useState([]);
    const [selectedPurpose, setSelectedPurpose] = useState('tithe');
    const [amount, setAmount] = useState(0);
  
    const handlePurposeChange = (e) => {
      setSelectedPurpose(e.target.value);
      setUserData({ ...userData, purpose: selectedPurpose });
    };

    const handleAmountChange = (e) => {
      setAmount(parseFloat(e.target.value + 50));
    };

    // const taxAmount = 50;

  
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    prayer: "",
    Bethel: "", 
    amount: "",
    paymentDescription: "",
    purpose: "",
    id: "",
    category: "",
    month: "",
  });
  const calculatePaymentAmount = () => {
    return parseFloat(userData.amount) * 100; // Convert to kobo
  };
  
  const config = {
    reference: new Date().getTime().toString(),
    email: userData.email,
    amount: userData.amount * 100, // Paystack accepts amount in kobo, so multiply by 100
    // publicKey: "pk_live_1c64818f3b0f7b300fb0ec33ca60c5a9f598ac09", 
    publicKey: "pk_live_21f9417f1a5dcc46c74738b3d9be49c56267f92e", // Replace with your Paystack public key
    // publicKey: "pk_test_a6299b3c7d13aff9b8fe0ffa7a5dc16a6442d86f", // Replace with your Paystack public key
  };


  const options = {
    public_key: "SBPUBK_WRTM2ICVS2JG8VRWNSBZR43XNPXZ8PEF",
    // public_key: "SBTESTPUBK_MUgNegcGcCwQTRKic9dBLOpnml2wlH7h",
    amount: userData.amount,
    tranref: new Date().getTime(),
    currency: "NGN",
    email: userData.email,
    full_name: userData.name,
    mobile_no: userData.phoneNumber,
    description: "BCS PAYMENT PORTAL",
    tokenize: false,
    planId: "",
    pocketId: "",
    vendorId: "",
    customization: {
      theme: {
        border_color: "#000000",
        background_color: "#004C64",
        button_color: "#0084A0",
      },
      payment_method: ["card", "account", "transfer", "wallet", "ussd"],
      display_fee: true, // true
      display_type: "embed", //inline
      logo: "https://pay.ooobcs.org/static/media/favicon.f4ce3832bbe056ed6df0.jpg",
    },
  };

  const close = (close) => {
  //  console.log(close);
   onClose();

 };

 const callback = (response, closeCheckout) => {
   console.log('Amos Said', response.message);
   if (response.message === 'Successful'){
     onSuccess(response, userData);

   }
   
   
   setTimeout(() => closeCheckout(), 2000);
 };
 
//  const initializePayment2 = useSeerbitPayment(options, callback, close);



  const onSuccessCallback = (response) => {
    onSuccess(response, userData);
  };

  const onCloseCallback = (response) => {
    onClose();
    // handleOrderAttempts(response, userData );
  };

  const onOrderAttempt = (response) => {
    handleOrderAttempts(response, userData );
  };


  // const initializePayment = usePaystackPayment(config);
  
  const [errorFields, setErrorFields] = useState({});
  const [error, setError] = useState('');
  const [Show, setShow] = useState(true);
  const [nav, setNav] = useState(true)
  const [navAcct, setNavAcct] = useState(true)
  const [anime, setAnime] = useState(false)



  const handlePayment2 = () => { 

    onOrderAttempt()
  
    const requiredFields = ['name', 'email', 'phoneNumber', 'amount', 'purpose'];
    const errorFieldsCopy = { ...errorFields };
    let hasError = false;
  
    requiredFields.forEach((field) => {
      if (!userData[field]) {
        errorFieldsCopy[field] = true;
        hasError = true;
      } else {
        errorFieldsCopy[field] = false;
      }
    });
  
    if (!validator.isEmail(userData.email)) {
      errorFieldsCopy.email = true;
      hasError = true;
    } else {
      errorFieldsCopy.email = false;
    }

    if (userData.purpose === 'direct tithe' || userData.purpose === 'personal tithe') {
      const additionalRequiredFields = ['location', 'Bethel', 'month', 'prayer'];
  
      additionalRequiredFields.forEach((field) => {
        if (!userData[field]) {
          errorFieldsCopy[field] = true;
          hasError = true;
        } else {
          errorFieldsCopy[field] = false;
        }
      });
  
      if (!userData.prayer) {
        errorFieldsCopy.prayer = true;
        hasError = true;
      } else {
        errorFieldsCopy.prayer = false;
      }
    }
  
    setErrorFields(errorFieldsCopy);
  
    if (hasError) {
      setError('Please fill in all required fields before making a payment.')
      return;
    } else {
      // initializePayment2();
    }
    // initializePayment2(options, callback, close);
  };
  const handlePayment = () => { 

    onOrderAttempt()
    
    const requiredFields = ['name', 'email', 'phoneNumber', 'purpose'];
    const errorFieldsCopy = { ...errorFields };
    let hasError = false;
  
    requiredFields.forEach((field) => {
      if (!userData[field]) {
        errorFieldsCopy[field] = true;
        hasError = true;
      } else {
        errorFieldsCopy[field] = false;
      }
    });
  
    if (!validator.isEmail(userData.email)) {
      errorFieldsCopy.email = true;
      hasError = true;
    } else {
      errorFieldsCopy.email = false;
    }

    if (userData.purpose === 'direct tithe' || userData.purpose === 'personal tithe') {
      const additionalRequiredFields = ['location', 'Bethel', 'month', 'prayer'];
  
      additionalRequiredFields.forEach((field) => {
        if (!userData[field]) {
          errorFieldsCopy[field] = true;
          hasError = true;
        } else {
          errorFieldsCopy[field] = false;
        }
      });
  
      if (!userData.prayer) {
        errorFieldsCopy.prayer = true;
        hasError = true;
      } else {
        errorFieldsCopy.prayer = false;
      }
    }
    
    alert('Successful ✅ We have received your application and we will contact you as soon as posible')
    setErrorFields(errorFieldsCopy);
    onSuccessCallback()

  
    if (hasError) {
      setError('Please fill in all required fields before making a payment.')
      return;
    } else {
      // onSuccessCallback()
      // initializePayment(onSuccessCallback, onCloseCallback);
      // onSuccessCallback()
      // onSuccess()
      alert('Successful ✅ We have received your application and we will contact you as soon as posible')
    }
    // onSuccessCallback()

    // initializePayment(onSuccessCallback, onCloseCallback);
    alert('Successful ✅ We have received your application and we will contact you as soon as posible')
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };


    const [searchQuery, setSearchQuery] = useState('');
  const filteredItems = Cat?.filter(item =>
    item.department_name.toLowerCase().includes(searchQuery.toLowerCase())
  );



  const handleNav = () => {
    setNav(!nav)
};
  const handleNavAcct = () => {
    setNavAcct(!navAcct)
};
const handleAnime = () => {
setAnime(!anime)
}



const [isVisible, setIsVisible] = useState(false);
const [show, setShows] = useState(false);

const listenToScroll = () => {
    let heightToHideFrom = 100;
    const winScroll = document.body.scroll ||
        document.documentElement.scroll;

    if (winScroll > heightToHideFrom) {
        isVisible &&      // to limit setting state only the first time
            setIsVisible(false);
    } else {
        setIsVisible(true);
    }
};

const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
}

useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
        window.removeEventListener("scroll", listenToScroll);
}, [])


// console.log(Show)
  return (
    <div className='lg:m-20 m-1 relative pt-5'>




<div className=' justify-between items-center lg:flex gap-20'>
            


            <div className={'lg:w-[50%] w-[100%]'}>
                <img src={Banner} alt="banner" className='shadow-md w-[100%] rounded-2xl' />
                </div>
       <div 
       className='w-[100%] mt-5 lg:w-[50%]'
      //  className='text-center mt-0 shadow-xl w-[100%] md:w-[100%] border-t-[1px] pb-10'
       >


       {/* <div 
        className='flex justify-center font-semibold mt-5 items-center '
        
        >
          Step
          <p
          className='bg-blue-600 items-center flex shadow-2xl justify-center text-white p-3 ml-2 rounded-full h-8 w-8 '
          > 
          <b>
            2
            </b>
          </p>
        </div> */}
       {/* {filteredItems.map((item, index) => (
  
  <>
  {
    !Show ?

<div
onClick={async () => { 
  // setUserData({...userData, purpose: item.department_name})
  setSearchQuery(item.department_name)
  setUserData({...userData, id: item.id, purpose: item.department_name})
  setShow(true)
}}
// onClick={() =>  setUserData({...userData, purpose: item.department_name})}
// name="purpose"
// value={userData.purpose}
// onChange={(e) => setUserData({ ...userData, purpose: e.target.value })}
className={errorFields.name ? 'border-red-400 border-2 bg-gray-200 rounded-md text-start text-bold mb-3 w-[70%] p-2' : 'border-2 bg-gray-200 border-gray-300 rounded-md text-center font-bold mb-3 w-[100%] p-2'}>
          <option
          key={index} >
           {item.department_name}
          </option>
</div>

: null
  }
  </>
        ))} */}
     {/* <input 
        value={searchQuery}
                        onChange={(e) => {
                          if(e) {
                            setSearchQuery(e.target.value)
                            setShow(false)
                          }else {
                          }}}
                          onClick={() => setShow(false)}
                        className='border-[0.7px] bg-gray-100 mt-5 outline-blue-300 border-gray-300 rounded-sm text-center mb-3 w-[70%] p-2'
                     type="search" name="" id="" placeholder='Select Category' /> */}
                <div>
                <label className="text-gray-0 text-center ">Full Name <b className='text-gray-400'>*</b></label>
                <br/>
                <input type="text" required
            value={userData.name}
            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
            className={errorFields.name ? 'border-red-400 border-2 bg-gray-200 rounded-md text-center mb-3 w-[70%] p-2' : 'border-[0.7px] outline-blue-300 bg-gray-100 border-gray-300 rounded-md text-start mb-3 w-[70%] p-2'}/>

                </div>
                
                <div>
                <label className="text-gray-0  text-center mt-2">Email Address <b className='text-gray-400'>*</b></label>
                <br/>
                <input type="email"
                    name="email"
                    value={userData.email}
                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                    className={errorFields.email ? 'border-red-400 border-2 bg-gray-200 rounded-md text-center mb-3 w-[70%] p-2' : 'border-[0.7px] bg-gray-100 outline-blue-300 border-gray-300 rounded-md text-start mb-3 w-[70%] p-2'}/>

                </div>
            
                <div>
                <label className="text-gray-0 mt-2 text-center ">Phone Number <b className='text-gray-400'>*</b> </label>
                <br/>
                <input type="tel"
                           name="phoneNumber"
                           value={userData.phoneNumber}
                           onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
                           className={errorFields.name ? 'border-red-400 border-2 bg-gray-200 rounded-md text-center mb-3 w-[70%] p-2' : 'border-[0.7px] bg-gray-100 border-gray-300 outline-blue-300 rounded-md text-start mb-3 w-[70%] p-2'}/>
                </div>
                {/* <div>
                <label className="text-gray-0 mt-2 text-center ">Amount <b className='text-red-600'>*</b></label>
                <br/>
                <input type="number" placeholder='₦ 0.00'
                            required
                            value={userData.amount}
                            onChange={(e) => setUserData({ ...userData, amount: e.target.value })}
                            className={errorFields.name ? 'border-red-400 border-2 bg-gray-200 rounded-md text-center mb-3 w-[70%] p-2' : 'border-[0.7px] bg-gray-100 border-gray-300 outline-blue-300 rounded-md text-start mb-3 w-[70%] p-2'}/>
                </div> */}


<div>
          <label className="text-gray-0 mt-2 text-center ">Your Skill Set <b className='text-gray-400'>*</b></label>
          <br/>
          <input type="text"
                    name="purpose"
                    required
                    value={userData.purpose}
                    onChange={(e) => setUserData({ ...userData, purpose: e.target.value })}
                    className={errorFields.name ? 'border-red-400 border-2 bg-gray-200 rounded-md text-center mb-3 w-[70%] p-2' : 'border-[0.7px] outline-blue-300 bg-gray-100 border-gray-300 rounded-md text-start mb-3 w-[70%] p-2'}/>
        </div>
<div>
          <label className="text-gray-0 mt-2 text-center ">Date Of Birth <b className='text-gray-400'>*</b></label>
          <br/>
          <input type="date"
                    name="paymentDescription"
                    required
                    className='m-2 px-16 text-gray-700 py-3 rounded-lg'
                    value={userData.paymentDescription}
                    onChange={(e) => setUserData({ ...userData, paymentDescription: e.target.value })}
                    // className={errorFields.name ? 'border-red-400 border-2 bg-gray-200 rounded-md text-center mb-3 w-[70%] p-2' : 'border-[0.7px] outline-blue-300 bg-gray-100 border-gray-300 rounded-md text-start mb-3 w-[70%] p-2'}
                    />
          </div>
<div>
          <label className="text-gray-0 m-5 text-center ">By Submiting this form, you agree to our <span onClick={() => setShows(!show)} className='text-[#555] cursor-pointer'>
            
            Terms And Conditions
            </span>
            <b className='text-gray-400'> *</b></label>
            {
              show &&
          <p className="text-gray-0 m-2 text-center ">
          UNIVERSE BY HUMANS
TECHNOLOGY SERVICES
          JOB DESCRIPTION: ONLINE
MARKETER
          Department: Marketing
Reports To: Marketing Manager
Summary:
We are seeking for a Marketing staff who is passionate and creative to join our team play
a key role in developing and executing successful marketing campaigns that drive brand
awareness, lead generation and sales. You will be responsible for a variety of tasks such
as such as content crelation and social media management.
Responsibilities:
.You will need to download and Sign up on Our Application; Universe By Humans. To get
acquainted with the technology
.Commit to bringing in every day users, advertisers and creators,.
.You will need to create a new account on Facebook , Instagram and TikTok solely for the
purpose of advertising our content; you will be provided with these content.
.You will need to Make a short creative video within a period of one month;; this video
should be a marketing tool for the app.
DEC. 15th, 2023
+234 703 998 9781 www.alluniverses.live
COMPANY STAMP
JOB DESCRIPTION: ONLINE
MARKETER
UNIVERSE BY HUMANS
TECHNOLOGY SERVICES
Company: Universe By Humans
This contract outlines the terms of payment between Universe By Humans Technology
Services ( hereinafter referred to as Company) and ……………………………… ( hereinafter
referred to as Employee) for the performance of marketing services.
1.Payment Structure.
1.1 Payment Base.
.Employee shall receive 40% commission at the end of a business week.
.Commissions will be paid via wire transfer.
2.Commissions.
Employees are to be paid 40% of the monies paid via the Application on any device using
Flutterwave as a payment method as this option forelisted will facilitate speedy payment
of commission weekly.
3.Term and Termination..
This Agreement shall commence on Employment ………………………….. and shall continue until
terminated by either party upon written notice.
.This Agreement shall be terminated immediately by either party in the event of causes
such as gross negligence, material breach of contract, or misconduct.
DEC. 15th, 2023
+234 703 998 9781 www.alluniverses.live
COMPANY STAMP
TERMS OF PAYMENT
UNIVERSE BY HUMANS
TECHNOLOGY SERVICES
DEC. 15th, 2023
+234 703 998 9781 www.alluniverses.live
COMPANY STAMP
TERMS OF PAYMENT
UNIVERSE BY HUMANS
TECHNOLOGY SERVICES
This Agreement constitutes the entire agreement between the parties with respect to the
subject matter hereof and supersedes all prior or contemporaneous communications,
representations, or agreement, wheteher oral or written. This Agreement may not be
assigned by either party without the prior written consent of the other party. This
agreement shall be governed and construed in accordance with the laws of The Federal
Republic of Nigeria. If any provision of this Agreement is to be held invalid or unenforceable
l, such provision shall be struck and the remaining provisions shall be in full force and effect.
IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first
written above.
Universe By Humans Technology
BY(authorized representative name)
Title:(authorized representative title)
(Marketing staff name)
(Marketing Staff signature)
4.General Provision.
This Non Disclosure Agreement (. ) is made and entered into as of this ( Date ) by and
between:
Universe By Humans; a social networking service for sharing photos,videos,audio and text
with its principal place of business ( address) and
(marketing staff name) residing at (address)
WHEREAS Universe By Humans is engaged in the business of sharing of photos, videos;
text and audio with monetary benefits services via social networking and possess certain
confidential information related to its business operations, including but not limited to
trade secrets, inventions, marketing plans, technical know-how,financial information and
other proprietary information (collectively ‘Confidential information ); and
WHEREAS Marketing Staff will have access to certain Confidential information of
Universe By Humans in the course of its engagement as Marketing Staff; and
WHEREAS We decide to protect Our Confidential Information from unauthorized
disclosure, and Marketing Staff wishes to its obligations of confidentiality.
NOW, THEREFORE, in consideration of the foregoing premises and the mutual covenant
contained therein, both parties agree as follows:
DEC. 15th, 2023
+234 703 998 9781 www.alluniverses.live
COMPANY STAMP
NONE DISCLOSURE
UNIVERSE BY HUMANS
TECHNOLOGY SERVICES
STAFF NAME:
“Confidential Information”means all information disclosed by company to Marketing Staff
whether orally, in writing,or by any other means, that is designated as confidential or
that, by its nature or the circumstances surrounding its disclosure, ought to be treated as
confidential. Confidential Information includes but it not limited to:
. Trade secrets,, kno-how and technical data:
. Customer lists, marketing plans and sales information:
.Financial information including; budgets,forecasts and pricing:
.Business plans and strategies:
.Unpublished documents and software: and
Any other information designated as confidential by Company.
2.Confidentiality Obligations.
Marketing Staff agrees to:
.Use all reasonable efforts to protect the confidentiality of Confidential information,
including using the degree of care that marketing staff uses to protect its own confidential
information:
.Hold in confidence all confidential information of Company and not disclose such to any
third party without the prior written consent of company:
..Not use Confidential Information for any purpose other than the performance of duties
for Company:
.Not reverse engineer any Confidential Information: and
.Upon termination of its engagement with Company, return or destroy all Confidential
Information in its possession or control.
DEC. 15th, 2023
+234 703 998 9781 www.alluniverses.live
COMPANY STAMP
NONE DISCLOSURE
UNIVERSE BY HUMANS
TECHNOLOGY SERVICES
1.Confidential Information.
The obligation of confidentiality set forth in this Agreement shall not apply to Confidential
Information that:
.Is or becomes public through no fault of Marketing's Staff;
.Was rightfully known by Marketing Staff prior to its disclosure by Company;
. Is independently developed by Marketing Staff without the use of any Confidential
Information; or
.Is required to be disclosed by law or a court order.
4.Remedies.
Marketing Staff agrees that any unauthorized disclosure of Confidential Information would
cause irreparable harm to Company for which monetary damages would be an inadequate
remedy. In the even of any unauthorized disclosure of Confidential Information, Company
shall be entitled to seek equitable relief including injunctive relief, to prevent further
disclosure and to protect its confidential informatio. Such remedies are in addition to any
other legal or equitable remedies available to Company.
5.Term and Termination
This Agreement shall remain in effect for a period Two (2) months following the termination
of Marketing Staff Engagement with Company. This agreement may be terminated by either
party upon written notice to the other party.
DEC. 15th, 2023
+234 703 998 9781 www.alluniverses.live
COMPANY STAMP
NONE DISCLOSURE
UNIVERSE BY HUMANS
TECHNOLOGY SERVICES
3.Exception.
6.General Provision.
This Agreement constitutes the entire agreement between the parties with respect to the
subject matter hereof and supersedes all prior or contemporaneous communications,
representations, or agreement, wheteher oral or written. This Agreement may not be
assigned by either party without the prior written consent of the other party. This
agreement shall be governed and construed in accordance with the laws of The Federal
Republic of Nigeria. If any provision of this Agreement is to be held invalid or unenforceable
l, such provision shall be struck and the remaining provisions shall be in full force and effect.
IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first
written above.
Universe By Humans Technology
BY(authorized representative name)
Title:(authorized representative title)
(Marketing staff name)
(Marketing Staff signature)
DEC. 15th, 2023
+234 703 998 9781 www.alluniverses.live
COMPANY STAMP
NONE DISCLOSURE
UNIVERSE BY HUMANS
TECHNOLOGY SERVICES
6.General Provision.
            </p>
            }
        </div>


{/* <div 
        className='flex justify-center font-semibold mt-5 items-center '
        
        >
        Final  Step
          <p
          className='bg-blue-600 items-center flex shadow-2xl justify-center text-white p-3 ml-2 rounded-full h-8 w-8 '
          > 
          <b>
            3
            </b>
          </p>
        </div> */}

<div className=" justify-center flex m-5">
        <button
          onClick={handlePayment}
          className="m-2 lg:ml-0 m-2 hover:bg-transparent text-center items-center flex font-bold py-5 px-16 drop-shadow-2xl shadow-2xl bg-white text-blue-500 rounded-lg"
          // className="rounded-lg md:w-[20%] w-[30%] mt-5 md:-mt-1 md:m-5 h-14 bg-gray-700"
        >
          <div className="flex items-center justify-center " >
                            {/* <img style={{ width: 45, height: 25, marginRight: 10 }} alt="we"
                             src={logo}/> */}
            <p className=" mr-2 text-blue-600 text-sm">Submit</p>
                             {/* src='https://static-00.iconduck.com/assets.00/paystack-icon-512x504-w7v8l6as.png'/>  */}
                                </div>
                   </button>
        {/* <br /> */}
        <br />
        <br />
        {/* <button
          className="mt-5 w-[30%] md:w-[20%] text-white font-bold text-2xl  md:mt-0 rounded-lg h-14 bg-gray-700"
        >
          cancel
        </button> */}
      </div> 

        </div>         
            </div>

        {/* </div> */}
        
    </div>
  )
}

export default PaystackForm