import React from 'react'
import './DevAbout.css'
import Terminal from '../../assets/logo.gif'

const DevAbout = () => {
    return (
        <div className='developer'>
            <div className="container">
                <div className="left">

                    <h2>ABOUT&nbsp; US™</h2>
                    <p>
                    Motivated to join the league of extraordinary designers’ founder and creative director - Adaku Stephanie Emenike A.K.A. Stephjas, established a menswear and womenswear label out of her dorm room. Stephjas started off by designing unique African printed asymmetrical pieces as then ‘nextberries by stephjas’ whilst in school designed for friends and family who they would use as live mannequins to advertise. However, the brand was put on pause up until 2019.
                    </p>
                    {/* <p>Checkout the <span className="blue">documentation </span>, the <span className="blue">quick start</span> or a guide below to integrate your project with thousands of tokens and billions of liquidity.</p> */}
                </div>
                <div className="right">
                    <div className="img-container">
                        <img src={Terminal} alt=""/>
                    </div>
                </div>
            </div>
            {/* <div style={{padding: 50}}>
            Motivated to join the league of extraordinary designers’ founder and creative director - Adaku Stephanie Emenike A.K.A. Stephjas, established a menswear and womenswear label out of her dorm room. Stephjas started off by designing unique African printed asymmetrical pieces as then ‘nextberries by stephjas’ whilst in school designed for friends and family who they would use as live mannequins to advertise. However, the brand was put on pause up until 2019.


            </div> */}
        </div>
    )
}

export default DevAbout
