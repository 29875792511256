import React from 'react'
import Footer from '../components/footer/Footer'
import HeroImage from '../components/HeroImageEVENT'
import Navbar from '../components/Navbar'
import Form from '../components/Form'

function Event() {
  return (
    <div >
    <Navbar />
    <HeroImage heading='ATTEND UNIVERSE' text='GRAND LAUNCHING CEREMONY 🎉 🍸 🥂 🎊' />
    <div style={{backgroundColor: '#000'}}>

    <Form />
    </div>
    <Footer/>
</div>
  )
}

export default Event