import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Video from '../components/Video'

const Home = () => {
    return (
        <div style={{ backgroundColor: '#000'}}>
            {/* <Navbar /> */}
            <Video />
            {/* <Footer /> */}
        </div>
    )
}

export default Home
